import CreateBadgeModalButton from "@/admin/experiences/badges/CreateBadgeModalButton"
import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ExperienceSettingsAdditionalDetails from "@/product/settings/ExperienceSettingsAdditionalDetails"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import ExperienceSettingsStatusRadioGroup from "@/product/settings/ExperienceSettingsStatusRadioGroup"
import ProductSlugInput from "@/product/settings/ProductSlugInput"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { ExperienceSettingsStatusRadioGroupFragment$key } from "@/product/settings/__generated__/ExperienceSettingsStatusRadioGroupFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoColorPicker from "@disco-ui/color/DiscoColorPicker"
import DiscoImageFormField from "@disco-ui/file/DiscoImageFormField"
import { FormControl } from "@material-ui/core"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { observer } from "mobx-react-lite"

interface Props {
  form: FormStore<ExperienceSettingsFormState, ExperienceSettingsFormMutation>
  productKey: ExperienceSettingsStatusRadioGroupFragment$key
}

function ExperienceSettingsDetailsFormFields({ form, productKey }: Props) {
  const experienceLabel = useLabel("admin_experience")
  const classes = useStyles()

  return (
    <>
      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            title={"Badge & Name"}
            tooltipContent={`A short name for this ${experienceLabel.singular} used for left navigation.`}
            sectionId={"experience_badge_name"}
          />
        }
        sublabel={`${experienceLabel.singular} name as it appears in your community's left navigation`}
        error={Boolean(form.errorsByField.name || form.errorsByField.badgeId)}
        errorMessages={form.errorsByField.name || form.errorsByField.badgeId}
      >
        <DiscoInput
          value={form.state.name}
          onChange={(e) => (form.state.name = e.target.value)}
          fullWidth
          data-testid={"ExperienceSettingsDetailsFormFields.name"}
          startAdornment={
            <CreateBadgeModalButton
              badge={form.state.badge!}
              testid={"ExperienceSettingsDetailsFormFields.badge-picker"}
            />
          }
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>

      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            title={"Description"}
            tooltipContent={`A short description for this ${experienceLabel.singular}. This is shown as a preview when you share this ${experienceLabel.singular}.`}
            sectionId={"experience_description"}
          />
        }
        sublabel={`A short description for this ${experienceLabel.singular}. This is shown as a preview when you share this ${experienceLabel.singular}.`}
        error={Boolean(form.errorsByField.description)}
        errorMessages={form.errorsByField.description}
      >
        <DiscoInput
          value={form.state.description}
          onChange={(e) => (form.state.description = e.target.value)}
          fullWidth
          multiline
          data-testid={"ExperienceSettingsDetailsFormFields.description"}
        />
      </DiscoFormControl>

      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            title={"Cover Image"}
            tooltipContent={`The cover image helps visually explain your ${experienceLabel.singular} and is used on ${experienceLabel.singular} cards.`}
            sectionId={"experience_cover_image"}
          />
        }
        sublabel={`Add an image for use on ${experienceLabel.singular} cards, registration, social share and more!`}
        error={Boolean(form.errorsByField.cover)}
        errorMessages={form.errorsByField.cover}
      >
        <DiscoImageFormField
          value={form.state.cover}
          onChange={(result) => {
            form.state.cover = result?.url || null
            form.state.coverAssetId = result?.id
          }}
          disableRemove
          suggestedDimensions={{ width: 1200, height: 600 }}
          cropperProps={{
            stencilProps: { aspectRatio: ASPECT_RATIOS.COVER_PHOTO },
          }}
          alterAspectRatio={false}
          maxWidth={"fit-content"}
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            sectionId={"experience_label"}
            title={"Label"}
            tooltipContent={`A label is used across the platform to indicate the type of experience.`}
          />
        }
        sublabel={`Add a descriptive label like 'course' or 'space'`}
        testid={"ExperienceSettingsDetailsFormFields.ProductTypeField"}
        error={Boolean(form.errorsByField.typeTag)}
        errorMessages={form.errorsByField.typeTag}
      >
        <DiscoInput
          value={form.state.typeTag!.label}
          onChange={(result) => (form.state.typeTag!.label = result.target.value)}
          inputProps={{ maxLength: 20 }}
          fullWidth
          endAdornment={
            <FormControl>
              <DiscoColorPicker
                className={classes.tagLabelColorPicker}
                hideText={true}
                value={form.state.typeTag?.color}
                onChange={(v) => (form.state.typeTag!.color = v.toUpperCase())}
              />
            </FormControl>
          }
        />
      </DiscoFormControl>
      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            title={"URL"}
            sectionId={"experience_page"}
            tooltipContent={`The base URL where your ${experienceLabel.singular} lives. Visible to different users depending on your configuration set in 'Availability' settings.`}
          />
        }
        sublabel={`The base URL for your ${experienceLabel.singular}`}
        testid={`ExperienceSettingsDetailsFormFields.ProductSlugField`}
        error={Boolean(form.errorsByField.experienceUrl)}
        errorMessages={form.errorsByField.experienceUrl}
      >
        <ProductSlugInput
          testid={"ExperienceSettingsDetailsFormFields"}
          value={form.state.experienceUrl}
          onChange={(e) => (form.state.experienceUrl = e.target.value)}
        />
      </DiscoFormControl>
      <DiscoDivider marginTop={2} marginBottom={3} />
      <ExperienceSettingsStatusRadioGroup form={form} productKey={productKey} />
      <DiscoDivider marginTop={2} marginBottom={3} />
      <ExperienceSettingsAdditionalDetails form={form} />
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  tagLabelColorPicker: {
    "&.Mui-focused": {
      border: "none",
    },
    "& button": {
      border: "2px solid #D5D9DE",
    },
    height: "40px",
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
}))

export const ExperienceSettingsDetailsFormFieldsSkeleton = () => {
  const classes = useStyles()
  const heights = [48, 48, 100, 140, 48, 96, 140, 140, 70]

  return (
    <div className={classes.skeletonContainer}>
      {heights.map((height, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <LabelFormFieldSkeleton key={i} height={height} width={350} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceSettingsDetailsFormFields),
  skeleton: ExperienceSettingsDetailsFormFieldsSkeleton,
})
