import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoIcon,
  DiscoIconButton,
  DiscoInput,
  DiscoInputProps,
  DiscoText,
} from "@disco-ui"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"

type Props = TestIDProps & Pick<DiscoInputProps, "value" | "onChange">

export default function ProductSlugInput(props: Props) {
  const { testid = "ProductSlugInput", value, onChange } = props
  const classes = useStyles()
  const copyToClipboard = useCopyToClipboard()

  return (
    <DiscoInput
      data-testid={`${testid}.product-slug-input`}
      value={value}
      onChange={onChange}
      fullWidth
      classes={{ input: classes.input, root: classes.inputRoot }}
      startAdornment={
        <DiscoText
          variant={"body-sm"}
          color={"text.disabled"}
          className={classes.startAdornment}
          align={"right"}
          truncateText={1}
        >{`${window.location.hostname}/p/`}</DiscoText>
      }
      endAdornment={
        <DiscoIconButton
          style={{ padding: 0 }}
          onClick={() => copyToClipboard(`${window.location.origin}/p/${value}`)}
        >
          <DiscoIcon icon={"copy"} />
        </DiscoIconButton>
      }
    />
  )
}

const useStyles = makeUseStyles({
  startAdornment: {
    wordBreak: "keep-all",
    padding: "6px 0 7px", // match the padding of the input text
  },
  inputRoot: {
    flexWrap: "wrap",
    height: "100%",
  },
  input: {
    flex: "1 0 fit-content",
  },
})
