import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FormStore from "@/core/form/store/FormStore"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { generateReadablePricing } from "@/pricing/pricingUtils"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { MembershipPlanExperiencePricingListItemFragment$key } from "@/product/settings/pricing/__generated__/MembershipPlanExperiencePricingListItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoChip, DiscoFormControl, DiscoSwitch, DiscoText } from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"

type MembershipBenefit = {
  id?: string | null
  membershipPlanId: string
}

interface Props {
  form: FormStore<
    CreateExperienceFormState | ExperienceSettingsFormState,
    ExperienceSettingsFormMutation
  >
  membershipPlanKey: MembershipPlanExperiencePricingListItemFragment$key
}

function MembershipPlanExperiencePricingListItem({ form, membershipPlanKey }: Props) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()

  const membershipPlan = useFragment<MembershipPlanExperiencePricingListItemFragment$key>(
    graphql`
      fragment MembershipPlanExperiencePricingListItemFragment on Product {
        id
        name
        cover
        membershipBenefits {
          totalCount
        }
        pricing {
          id
          amountCents
          frequency
          kind
        }
      }
    `,
    membershipPlanKey
  )

  const existingBenefit: MembershipBenefit | undefined = form.state.benefits.find(
    (b) => b.membershipPlanId === membershipPlan.id
  )
  const existingBenefitRef = useRef(existingBenefit)
  const [isToggleOn, setIsToggleOn] = useState(!!existingBenefit)

  return (
    <DiscoFormControl
      error={Boolean(form.errorsByField.pricing)}
      errorMessages={form.errorsByField.pricing}
      className={classes.form}
    >
      <div>
        {/* Header */}
        <div className={classes.header}>
          <div className={classes.details}>
            <DiscoImage
              className={classes.cover}
              src={membershipPlan.cover || defaultThumbnail}
            />

            <div>
              {/* Name */}
              <DiscoText variant={"body-md-600"}>{membershipPlan.name}</DiscoText>

              {/* Price */}
              {membershipPlan.pricing && (
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {generateReadablePricing(
                    membershipPlan.pricing,
                    activeOrganization.currency
                  )}
                </DiscoText>
              )}
            </div>
          </div>

          <div>
            {/* Experiences */}
            <DiscoChip
              label={pluralize(
                "Product",
                membershipPlan.membershipBenefits?.totalCount,
                true
              )}
            />

            {/* Include Checkbox */}
            <DiscoSwitch checked={isToggleOn} onChange={handleTogglePlan} />
          </div>
        </div>
      </div>
    </DiscoFormControl>
  )

  function handleTogglePlan() {
    // If toggling off, remove the benefit
    if (isToggleOn) {
      form.state.benefits = observable.array(
        form.state.benefits.filter((b) => b.membershipPlanId !== membershipPlan.id)
      )
    }

    // If toggling on, add the benefit
    else if (existingBenefitRef.current) {
      // If benefit already exists, add it back
      form.state.benefits.push(existingBenefitRef.current)
    }

    // Create benefit with null ID so the resolver knows to create one
    else {
      form.state.benefits.push({
        id: null,
        membershipPlanId: membershipPlan.id,
      })
    }

    setIsToggleOn(!isToggleOn)
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    marginBottom: 0,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  details: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  cover: {
    height: "50px",
    display: "block",
    width: "auto",
    borderRadius: theme.measure.borderRadius.medium,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))

export const MembershipPlanExperiencePricingListItemSkeleton = () => {
  const heights = [60]

  return (
    <div>
      {heights.map((height) => (
        <LabelFormFieldSkeleton key={height} height={height} width={350} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(MembershipPlanExperiencePricingListItem),
  skeleton: MembershipPlanExperiencePricingListItemSkeleton,
})
