import CreateExperienceButton from "@/admin/experiences/create/CreateExperienceButton"
import CreatePathwayButton from "@/admin/pathways/CreatePathwayButton"
import { useLabel } from "@/core/context/LabelsContext"
import CreateNavSectionButton from "@/organization/common/sidebar/nav-section/CreateNavSectionButton"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import { DiscoDropdown, DiscoDropdownProps } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"

type Props = {
  children: DiscoDropdownProps["menuButton"]
} & TestIDProps

export default function ProductsSidebarAddButton({
  testid = "ProductsSidebarAddButton",
  children,
}: Props) {
  const productLabel = useLabel("admin_experience")
  const pathwayLabel = useAdminProductLabel("pathway")
  const { learningPathways } = useFeatureFlags()

  return (
    <DiscoDropdown
      menuButton={children}
      horizontal={"right"}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <CreateExperienceButton>
        {({ onClick }) => (
          <DiscoDropdownItem
            testid={`${testid}.product`}
            onClick={onClick}
            title={`Add ${productLabel.singular}`}
          />
        )}
      </CreateExperienceButton>
      {learningPathways && (
        <CreatePathwayButton>
          {(pathwayButtonProps) => (
            <DiscoDropdownItem
              {...pathwayButtonProps}
              testid={`${testid}.pathway`}
              title={`Add ${pathwayLabel.singular}`}
            />
          )}
        </CreatePathwayButton>
      )}
      <CreateNavSectionButton navSectionIndex={0} kind={"products"}>
        {({ onClick }) => (
          <DiscoDropdownItem
            testid={`${testid}.nav-section`}
            onClick={onClick}
            title={"Add Section"}
          />
        )}
      </CreateNavSectionButton>
    </DiscoDropdown>
  )
}
