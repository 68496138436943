import { useActiveProduct } from "@/core/context/ActiveProductContext"
import MemberCurriculumProgressBar, {
  MemberCurriculumProgressBarProps,
  MemberCurriculumProgressBarSkeleton,
} from "@/product/reports/page/curriculum/MemberCurriculumProgressBar"
import { MemberCurriculumProgressBarWithQueryQuery } from "@/product/reports/page/curriculum/__generated__/MemberCurriculumProgressBarWithQueryQuery.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import classNames from "classnames"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

type Props = Omit<MemberCurriculumProgressBarProps, "memberKey">

function MemberCurriculumProgressBarWithQuery(props: Props) {
  const classes = useStyles()
  const activeProduct = useActiveProduct()!
  const canManage = activeProduct.viewerPermissions.has("members.manage")
  const learnerMemberId = canManage ? "" : activeProduct.viewerMembership?.id || ""

  const { member } = useLazyLoadQuery<MemberCurriculumProgressBarWithQueryQuery>(
    graphql`
      query MemberCurriculumProgressBarWithQueryQuery($id: ID!) {
        member: node(id: $id) {
          ... on ProductMembership {
            ...MemberCurriculumProgressBarFragment
          }
        }
      }
    `,
    {
      id: learnerMemberId,
    }
  )

  if (!member) return null

  return (
    <div className={classNames(classes.option, classes.progress)}>
      <MemberCurriculumProgressBar
        memberKey={member}
        hideLabels={false}
        width={"100%"}
        className={classes.progressBar}
        {...props}
      />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  option: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  progress: {
    width: "100%",
  },
  progressBar: {
    width: "100%",
  },
}))

export const MemberCurriculumProgressBarWithQuerySkeleton: React.FC = () => {
  return <MemberCurriculumProgressBarSkeleton />
}

export default Relay.withSkeleton<Props>({
  component: MemberCurriculumProgressBarWithQuery,
  skeleton: MemberCurriculumProgressBarWithQuerySkeleton,
})
