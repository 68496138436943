import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import {
  TrackAiActivityInput,
  useTrackAIActivityMutation,
} from "@/reporting/__generated__/useTrackAIActivityMutation.graphql"
import { useCallback } from "react"
import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"

/**
 * Track a user activity event
 */
function useTrackAIActivity() {
  const activeOrganization = useActiveOrganization()!
  const [commit] = useMutation<useTrackAIActivityMutation>(graphql`
    mutation useTrackAIActivityMutation($input: TrackAiActivityInput!) {
      trackAiActivity(input: $input) {
        errors {
          field
          message
        }
      }
    }
  `)

  return useCallback(
    (args: Pick<TrackAiActivityInput, "kind" | "entityId">) => {
      const { kind, entityId } = args

      commit({
        variables: {
          input: {
            organizationId: activeOrganization.id,
            kind,
            entityId,
          },
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}

export default useTrackAIActivity
