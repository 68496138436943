/**
 * @generated SignedSource<<fd506d2ff23083a60d9c37acab43959b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ExperienceSettingsStatusRadioGroupFragment$data = {
  readonly type: ProductType;
  readonly status: ProductStatus;
  readonly isInPublishedPathway: boolean;
  readonly " $fragmentType": "ExperienceSettingsStatusRadioGroupFragment";
};
export type ExperienceSettingsStatusRadioGroupFragment$key = {
  readonly " $data"?: ExperienceSettingsStatusRadioGroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceSettingsStatusRadioGroupFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceSettingsStatusRadioGroupFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInPublishedPathway",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "d1d4231c9bff809df9ff953bef11fa7c";

export default node;
