import {
  postWebViewMessage,
  WebViewMessageType,
} from "@/apps/util/hooks/useWebViewMessage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { LabelData } from "@/core/context/LabelsContext"
import { ProductRegistrationAvailability } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { LabelKind } from "@/core/context/__generated__/LabelsContextActiveOrganizationFragment.graphql"
import useIsWebView from "@/product/util/hook/useIsWebView"
import {
  ProductType,
  productUtils_useIsProductLiveFragment$key,
} from "@/product/util/__generated__/productUtils_useIsProductLiveFragment.graphql"
import { DiscoIconKinds } from "@disco-ui"
import {
  calculateRemainingTime,
  countdownDataToMessage,
} from "@utils/hook/useCountDownTimer"
import { toDate } from "date-fns-tz"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql, RecordSourceProxy } from "relay-runtime"
import { isOccurrenceLive } from "../../occurrence/util/occurrenceUtils"
import { DATE_FORMAT } from "../../utils/time/timeConstants"
import { compensateForTimeZone, formatDateWithOptions } from "../../utils/time/timeUtils"
import { Product } from "../api/productApiModels"

namespace ProductUtils {
  export function getProductStartDateTime(product: Product): Date | null {
    if (product?.type === "course") {
      return product.start_date
        ? compensateForTimeZone(new Date(product.start_date))
        : null
    }

    return null
  }

  export function displayDuration(product: {
    startDate?: string | null
    endDate?: string | null
    startsAt?: string | null
    endsAt?: string | null
  }): string {
    const start = product.startDate || product.startsAt
    const end = product.endDate || product.endsAt
    if (!start || !end) return ""
    const duration = calculateRemainingTime(new Date(end), new Date(start))
    // Round up product duration to 1 day since we don't allow finer grained control
    if (duration.days < 1) duration.days = 1
    return countdownDataToMessage(duration)
  }

  export function useIsProductLive(
    productKey: productUtils_useIsProductLiveFragment$key
  ): boolean {
    const product = useFragment<productUtils_useIsProductLiveFragment$key>(
      graphql`
        fragment productUtils_useIsProductLiveFragment on Product {
          type
          nextOrLastOccurrence {
            datetimeRange
          }
        }
      `,
      productKey
    )

    return isOccurrenceLive(product.nextOrLastOccurrence?.datetimeRange)
  }

  export function getStartDateText(type: ProductType, startsAt: string | null): string {
    if (!startsAt) return type === "course" ? "No fixed duration" : ""

    switch (type) {
      case "course":
        return formatDateWithOptions({
          format: DATE_FORMAT.DEFAULT,
        })(toDate(startsAt))
      default:
        return ""
    }
  }

  /** Labels for experience registration availability */
  export function getAvailabilityLabel(
    v: ProductRegistrationAvailability,
    membersLabel: LabelData
  ): string {
    switch (v) {
      case "hidden":
        return "Invite Only"
      case "private":
        return `${membersLabel.plural} Only`
      case "public":
      default:
        return "Public"
    }
  }

  /** Icons for experience registration availability */
  export function getAvailabilityIcon(
    v: ProductRegistrationAvailability
  ): DiscoIconKinds {
    switch (v) {
      case "hidden":
        return "lock"
      case "private":
        return "lock-unlocked"
      case "public":
      default:
        return "users"
    }
  }

  export function hasActiveWaitingRoom(
    product: {
      waitingRoomEndsAt: string | null
      startDate: string | null
    },
    /** evaluate if waiting room is active on a specific date */
    compareAgainst?: Date
  ) {
    const waitingRoomEndsAt = product.waitingRoomEndsAt
      ? new Date(product.waitingRoomEndsAt)
      : null

    // if there is no start date, access is granted immediately and the waiting room is never active
    if (!product.startDate) return false

    const now = new Date()

    return waitingRoomEndsAt
      ? // if the waiting room end date is after the date we're comparing against, the waiting room is active
        isAfter(waitingRoomEndsAt, compareAgainst || now)
      : // otherwise, if the date we're comparing against is before the product start, the waiting room is active
        isBefore(
          compareAgainst || now,
          compensateForTimeZone(new Date(product.startDate))
        )
  }

  export function getProductTypeLabel(
    labels: Record<LabelKind, LabelData>,
    type: ProductType
  ) {
    const productTypeLabels: Record<ProductType, string> = {
      course: labels.experience.singular,
      membership_plan: "Membership",
      community_event: "Community Event",
      pathway: labels.pathway.singular,
      "%future added value": "",
    }
    return productTypeLabels[type]
  }

  export function useInvalidateSidebar() {
    const activeOrganization = useActiveOrganization()
    const isWebView = useIsWebView()
    return useCallback(
      (store: RecordSourceProxy) => {
        if (isWebView) {
          return postWebViewMessage({ type: WebViewMessageType.refetch_products })
        }

        if (!activeOrganization?.id) return
        const orgRecord = store.get(activeOrganization.id)
        if (!orgRecord) return
        ConnectionHandler.getConnections(
          orgRecord,
          "ProductsSidebar__productsNavSections"
        ).forEach((connection) => connection.invalidateRecord())
      },
      [activeOrganization?.id, isWebView]
    )
  }
}

export default ProductUtils
