import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import { useExperienceSettingsSelectedPlans_membershipPlansFragment$key } from "@/product/settings/hooks/__generated__/useExperienceSettingsSelectedPlans_membershipPlansFragment.graphql"
import Relay from "@/relay/relayUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type Props = {
  organizationKey: useExperienceSettingsSelectedPlans_membershipPlansFragment$key
  benefits: ExperienceSettingsFormState["benefits"]
}

function useExperienceSettingsSelectedPlans({ organizationKey, benefits }: Props) {
  const organization =
    useFragment<useExperienceSettingsSelectedPlans_membershipPlansFragment$key>(
      graphql`
        fragment useExperienceSettingsSelectedPlans_membershipPlansFragment on Organization {
          id
          membershipPlans: products(type: "membership_plan") {
            edges {
              node {
                id
                name
                cover
                isFreeMembership
                ...MembershipPlanExperiencePricingListItemFragment @relay(mask: false)
                ...MembershipPlanExperiencePricingListItemFragment
              }
            }
          }
        }
      `,
      organizationKey
    )

  const availableMembershipPlans = Relay.connectionToArray(organization?.membershipPlans)
  const defaultFreePlan = availableMembershipPlans.find((p) => p.isFreeMembership)
  const hasCustomPlans = availableMembershipPlans.some((p) => !p.isFreeMembership)

  // Filter for the selected membership plans
  const selectedPlanIds = benefits.length ? benefits.map((b) => b.membershipPlanId) : []
  const selectedPlans = availableMembershipPlans.filter((ap) =>
    selectedPlanIds.includes(ap.id)
  )
  return { selectedPlans, hasCustomPlans, availableMembershipPlans, defaultFreePlan }
}

export default useExperienceSettingsSelectedPlans
