import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LeaveProductButtonFragment$key } from "@/product/member/admin/enrollment/__generated__/LeaveProductButtonFragment.graphql"
import { UnenrollProductMemberButtonMutation } from "@/product/member/admin/enrollment/__generated__/UnenrollProductMemberButtonMutation.graphql"
import { useProductLabel } from "@/product/util/hook/useProductLabel"
import { displayToast } from "@components/toast/ToastProvider"
import { DiscoText } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import React, { useState } from "react"
import { useFragment } from "react-relay"
import { generatePath, useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

type Props = {
  productKey: LeaveProductButtonFragment$key
  children: OverridableDiscoButtonProps["children"]
}

const LeaveProductButton: React.FC<Props> = (props) => {
  const { productKey, children } = props
  const [isModalOpen, setModal] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const product = useFragment<LeaveProductButtonFragment$key>(
    graphql`
      fragment LeaveProductButtonFragment on Product {
        name
        slug
        type
        viewerMembership {
          id
          organizationMembershipId
        }
      }
    `,
    productKey
  )
  const label = useProductLabel(product.type)

  const form = useFormStore<UnenrollProductMemberButtonMutation>(
    graphql`
      mutation LeaveProductButtonMutation($input: UnenrollProductMemberInput!) {
        response: unenrollProductMember(input: $input) {
          productMembership {
            id
            status
            organizationMembership {
              organization {
                ...ProductsSidebar_NavSectionsFragment
                ...ProductsSidebarList_OrganizationFragment
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      productMembershipId: product.viewerMembership?.id || "",
    }
  )

  return (
    <>
      <OverridableDiscoButton onClick={() => setModal(true)}>
        {children}
      </OverridableDiscoButton>
      <DiscoWarningModal
        testid={"LeaveProductButton.unenroll-member-warning-modal"}
        isOpen={isModalOpen}
        onClose={() => setModal(false)}
        title={`Are you sure you want to leave the ${label.singular}?`}
        description={
          <>
            <DiscoText>
              {`You will no longer be able to access the ${label.singular}: ${product.name}.`}
            </DiscoText>
          </>
        }
        confirmationButtonProps={{
          onClick: handleUnenrollMember,
          shouldDisplaySpinner: form.isSubmitting,
          children: `Leave the ${label.singular}`,
        }}
        modalContentLabel={`Leave the ${label.singular}`}
      />
    </>
  )

  async function handleUnenrollMember() {
    const { didSave } = await form.submit({
      productMembershipId: form.state.productMembershipId,
    })
    if (!didSave) return

    const isViewingProduct = location.pathname.startsWith(
      generatePath(ROUTE_NAMES.PRODUCT.ROOT, { productSlug: product.slug })
    )

    displayToast({
      message: `Left ${product.name}`,
      testid: "LeaveProductButton",
    })
    setModal(false)

    if (isViewingProduct) {
      history.push(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
    }
  }
}

export default LeaveProductButton
