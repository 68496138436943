/**
 * @generated SignedSource<<5f8d3679bca4d2bf353d91b59e2fda2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
export type ProductRegistrationAvailability = "public" | "private" | "hidden" | "%future added value";
export type ProductRegistrationType = "closed" | "open" | "application" | "waitlist" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type EditExperienceInput = {
  id: string;
  badge?: BadgeInput | null;
  name?: string | null;
  description?: string | null;
  slug?: string | null;
  typeTag?: TagInput | null;
  experienceUrl?: string | null;
  cover?: string | null;
  coverAssetId?: string | null;
  landingPage?: ProductLandingPageInput | null;
  landingPageUrl?: string | null;
  status?: ProductStatus | null;
  startDate?: string | null;
  endDate?: string | null;
  waitingRoomEndsAt?: string | null;
  sendEarlyAccessStartedEmail?: boolean | null;
  adminCanLearnMode?: boolean | null;
  registrationAvailability?: ProductRegistrationAvailability | null;
  registrationType?: ProductRegistrationType | null;
  applicationQuestions?: ReadonlyArray<ProductQuestionInput> | null;
  capacity?: number | null;
  waitlistUrl?: string | null;
  waitlistCtaLabel?: string | null;
  sendNewCommentEmail?: boolean | null;
  sendCourseReminderEmail?: boolean | null;
  sendCourseRegistrationEmailToAttendees?: boolean | null;
  sendAssignmentDueNotifications?: boolean | null;
  replyToEmailAddress?: string | null;
  richEditorCheckoutDescription?: string | null;
  isAutoJoined?: boolean | null;
  membershipBenefits?: ReadonlyArray<EditExperienceMembershipBenefitInput> | null;
};
export type BadgeInput = {
  icon?: string | null;
  color?: string | null;
  emoji?: string | null;
  mediaUrl?: string | null;
  assetId?: string | null;
  kind: BadgeKind;
};
export type TagInput = {
  label?: string | null;
  color?: string | null;
};
export type ProductLandingPageInput = {
  mode?: string | null;
  metaTitle?: string | null;
  metaDescription?: string | null;
  metaImageUrl?: string | null;
  metaImageAssetId?: string | null;
};
export type ProductQuestionInput = {
  id: string;
  richEditorBody?: string | null;
  type: string;
  isRequired: boolean;
};
export type EditExperienceMembershipBenefitInput = {
  id?: string | null;
  membershipPlanId: string;
  productId: string;
  pricing: UpdatePricingInput;
};
export type UpdatePricingInput = {
  amountCents: number;
  frequency?: PricingFrequency | null;
  kind: PricingKind;
};
export type RegistrationExperienceDetailsForm_CourseMutation$variables = {
  input: EditExperienceInput;
};
export type RegistrationExperienceDetailsForm_CourseMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly richEditorCheckoutDescription: string | null;
      readonly cover: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type RegistrationExperienceDetailsForm_CourseMutation = {
  variables: RegistrationExperienceDetailsForm_CourseMutation$variables;
  response: RegistrationExperienceDetailsForm_CourseMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditExperienceMutationResponse",
    "kind": "LinkedField",
    "name": "editExperience",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "richEditorCheckoutDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cover",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationExperienceDetailsForm_CourseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationExperienceDetailsForm_CourseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a83a2539709db37a40b0f436507ee5b6",
    "id": null,
    "metadata": {},
    "name": "RegistrationExperienceDetailsForm_CourseMutation",
    "operationKind": "mutation",
    "text": "mutation RegistrationExperienceDetailsForm_CourseMutation(\n  $input: EditExperienceInput!\n) {\n  response: editExperience(input: $input) {\n    node {\n      id\n      richEditorCheckoutDescription\n      cover\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d0c5fa01eb2fd50da8fda899b05d951";

export default node;
