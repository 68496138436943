import InlineCoverPhotoForm from "@/content/inline/hero-section/InlineCoverPhotoForm"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import { RegistrationExperienceDetailsFormFragment$key } from "@/product/register/__generated__/RegistrationExperienceDetailsFormFragment.graphql"
import { RegistrationExperienceDetailsForm_CourseMutation } from "@/product/register/__generated__/RegistrationExperienceDetailsForm_CourseMutation.graphql"
import { RegistrationExperienceDetailsForm_PathwayMutation } from "@/product/register/__generated__/RegistrationExperienceDetailsForm_PathwayMutation.graphql"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import Form from "@components/form/Form"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  productKey: RegistrationExperienceDetailsFormFragment$key
  classes: ClassNameMap<"contentContainer" | "coverPhoto">
  onPage?: boolean
}

function RegistrationExperienceDetailsForm({
  productKey,
  classes: propClasses,
  onPage,
}: Props) {
  const { setUnsavedChanges, handleLeave } = useUnsavedChangesModalContext()

  const product = useFragment<RegistrationExperienceDetailsFormFragment$key>(
    graphql`
      fragment RegistrationExperienceDetailsFormFragment on Product {
        id
        type
        cover
        richEditorCheckoutDescription
      }
    `,
    productKey
  )

  const initialInput = {
    richEditorCheckoutDescription: EditorUtils.getInitialEditorState(
      product.richEditorCheckoutDescription
    ),
    cover: product.cover as string | null,
    coverAssetId: null as string | null,
  }

  const courseForm = useFormStore<
    RegistrationExperienceDetailsForm_CourseMutation,
    typeof initialInput
  >(
    graphql`
      mutation RegistrationExperienceDetailsForm_CourseMutation(
        $input: EditExperienceInput!
      ) {
        response: editExperience(input: $input) {
          node {
            id
            richEditorCheckoutDescription
            cover
          }
          errors {
            field
            message
          }
        }
      }
    `,
    initialInput
  )

  const pathwayForm = useFormStore<
    RegistrationExperienceDetailsForm_PathwayMutation,
    typeof initialInput
  >(
    graphql`
      mutation RegistrationExperienceDetailsForm_PathwayMutation(
        $input: UpdatePathwayInput!
      ) {
        response: updatePathway(input: $input) {
          node {
            id
            richEditorCheckoutDescription
            cover
          }
          errors {
            field
            message
          }
        }
      }
    `,
    initialInput
  )
  const isPathway = product.type === "pathway"
  const form = isPathway ? pathwayForm : courseForm

  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  const productLabel = useAdminProductLabel(product.type).singular
  const showButtons = form.isChanged
  const classes = useStyles({ showButtons, onPage })
  const isMobile = useIsMobile()

  return (
    <Form
      id={"RegistrationExperienceDetailsForm"}
      onSubmit={handleSubmit}
      customClassName={classes.container}
      classes={{ formFieldsContainer: classes.formFieldsContainer }}
    >
      <ScrollShadowContainer
        classes={{
          parentContainer: classes.parentContainer,
          scrollContainer: classes.scrollContainer,
        }}
        disableScroll={onPage || isMobile}
      >
        <div
          className={classNames(classes.contentContainer, propClasses.contentContainer)}
          data-testid={"RegistrationExperienceDetails"}
        >
          {form.state.cover && (
            <InlineCoverPhotoForm
              coverPhoto={form.state.cover}
              classes={{
                coverPhoto: propClasses.coverPhoto,
                coverContainer: classes.coverContainer,
              }}
              handleChangeImage={handleChangeCover}
            />
          )}
          <DiscoEditor
            key={form.key}
            disableMarginRight
            defaultValue={form.state.richEditorCheckoutDescription}
            onChange={(v) => (form.state.richEditorCheckoutDescription = v)}
            placeholder={"Click here to add a description..."}
            testid={"RegistrationExperienceDetails.editable.description"}
          />
        </div>
      </ScrollShadowContainer>
      {showButtons && (
        <div
          className={classes.buttonsContainer}
          data-testid={"RegistrationExperienceDetails.buttons"}
        >
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            onClick={handleCancel}
            testid={"RegistrationExperienceDetails.cancel"}
          >
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            id={"RegistrationExperienceDetailsForm"}
            form={form}
            testid={"RegistrationExperienceDetails.save"}
          >
            {"Save Changes"}
          </Form.SubmitButton>
        </div>
      )}
    </Form>
  )

  function handleChangeCover(result: MediaResult) {
    form.state.cover = result.url
    form.state.coverAssetId = result.id
  }

  function handleCancel() {
    handleLeave({
      onLeave: () => form.reset(),
    })
  }

  async function handleSubmit() {
    const result = { didSave: false }

    if (isPathway) {
      const { didSave } = await pathwayForm.submit({
        productId: product.id,
        ...form.changedState,
      })
      result.didSave = didSave
    } else {
      const { didSave } = await courseForm.submit({
        id: product.id,
        ...form.changedState,
      })
      result.didSave = didSave
    }

    if (!result.didSave) return

    // Reset any unsaved changes
    if (setUnsavedChanges) {
      setUnsavedChanges(false)
    }

    displaySuccessToast({
      message: `${productLabel} updated!`,
      testid: "RegistrationExperienceDetails.success-toast",
    })
  }
}

type StyleProps = {
  showButtons: boolean
  onPage?: boolean
}

const EXPERIENCE_DETAILS_DESKTOP_BUTTONS_HEIGHT = "80px"
export const EXPERIENCE_DETAILS_MOBILE_BUTTONS_HEIGHT = "64px"

const useStyles = makeUseStyles((theme) => ({
  container: ({ onPage }: StyleProps) => ({
    position: "relative",
    ...styleIf(!onPage, {
      overflowY: "auto",
    }),

    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
  }),
  contentContainer: {
    overflow: "hidden",
  },
  formFieldsContainer: {
    height: "100%",
  },
  coverContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  parentContainer: ({ showButtons, onPage }: StyleProps) => ({
    ...styleIf(showButtons && !onPage, {
      paddingBottom: EXPERIENCE_DETAILS_DESKTOP_BUTTONS_HEIGHT,
    }),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  }),
  scrollContainer: {
    [theme.breakpoints.down("sm")]: {
      overflow: "unset",
    },
  },
  buttonsContainer: ({ onPage }: StyleProps) => ({
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: theme.spacing(0, 2.5),
    gap: theme.spacing(1.5),
    ...styleIf(!onPage, {
      borderTop: `1px solid ${theme.palette.groovy.neutral[100]}`,
      height: EXPERIENCE_DETAILS_DESKTOP_BUTTONS_HEIGHT,
      position: "absolute",
      bottom: 0,
      zIndex: 2,
    }),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1.5),

      ...styleIf(!onPage, {
        height: EXPERIENCE_DETAILS_MOBILE_BUTTONS_HEIGHT,
      }),
    },
  }),
}))

export default observer(RegistrationExperienceDetailsForm)
