import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import {
  EditExperienceInput,
  ExperienceSettingsFormMutation,
  ProductStatus,
} from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { ExperienceSettingsStatusRadioGroupFragment$key } from "@/product/settings/__generated__/ExperienceSettingsStatusRadioGroupFragment.graphql"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import Relay from "@/relay/relayUtils"
import { DiscoFormControl } from "@disco-ui"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  form:
    | FormStore<EditExperienceInput, ExperienceSettingsFormMutation>
    | PathwaySettingsFormStore
  productKey: ExperienceSettingsStatusRadioGroupFragment$key
}
function ExperienceSettingsStatusRadioGroup({
  form,
  testid = "ExperienceSettingsStatusRadioGroup",
  productKey,
}: Props) {
  const labels = useLabels()
  const theme = useTheme()

  const product = useFragment<ExperienceSettingsStatusRadioGroupFragment$key>(
    graphql`
      fragment ExperienceSettingsStatusRadioGroupFragment on Product {
        type
        status
        isInPublishedPathway
      }
    `,
    productKey
  )
  const productLabel = useAdminProductLabel(product.type).singular
  const isPathway = product.type === "pathway"
  const adminLabels = isPathway
    ? `${labels.organization_admin.plural} and ${labels.organization_owner.plural}`
    : `${labels.product_instructor.plural}, ${labels.product_admin.plural}, ${labels.organization_admin.plural} and ${labels.organization_owner.plural}`
  const disabledReason = getDisabledReason()

  return (
    <DiscoFormControl
      label={
        <ExperienceSettingsLabel
          title={"Status"}
          sectionId={"experience_status"}
          tooltipContent={`In draft mode, only ${adminLabels} are able to see your ${productLabel}. When published, ${labels.admin_member.plural} may register and join.`}
        />
      }
      sublabel={`Choose to publish for ${labels.admin_member.plural} to see or draft to continue editing before it's visible.`}
      error={Boolean(form.errorsByField.status)}
      errorMessages={form.errorsByField.status}
    >
      <RadioGroup
        onChange={handleStatusChange}
        value={form.state.status}
        row
        style={{ gap: theme.spacing(1.5) }}
      >
        <DiscoRadioBox
          icon={"feather"}
          value={"draft"}
          title={"Draft"}
          testid={`${testid}.radio.draft`}
          subtitle={`Continue editing worry free. Drafts are only visible to ${adminLabels}.`}
          disabled={Boolean(disabledReason)}
          tooltip={disabledReason}
        />
        <DiscoRadioBox
          icon={"rocket"}
          value={"published"}
          title={"Published"}
          testid={`${testid}.radio.publish`}
          subtitle={`Launch ${productLabel} live and allow to be discovered and registered for.`}
        />
      </RadioGroup>
    </DiscoFormControl>
  )

  function handleStatusChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.status = event.target.value as ProductStatus
  }

  function getDisabledReason() {
    if (product.isInPublishedPathway)
      return `Cannot change ${productLabel} status back to Draft because this ${productLabel} is part of a published Pathway.`
    if (isPathway && product.status === "published")
      return `Cannot change ${productLabel} back to Draft.`
    return undefined
  }
}

export default Relay.withSkeleton({
  component: observer(ExperienceSettingsStatusRadioGroup),
  skeleton: () => null,
})
