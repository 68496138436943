import useIsExplorePageShown from "@/admin/community/product-list/hooks/useIsExporePageShown"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useProductSlug } from "@/core/route/util/routeUtils"
import MyExperiencesListItem from "@/organization/common/sidebar/my-experiences-list/MyExperiencesListItem"
import PathwaySidebarItem from "@/organization/common/sidebar/pathway/PathwaySidebarItem"
import { ProductsSidebarProductsDragDrop } from "@/product/sidebar/ProductsSidebarDragDropProvider"
import { ProductsSidebarList_NavSectionFragment$key } from "@/product/sidebar/__generated__/ProductsSidebarList_NavSectionFragment.graphql"
import { ProductsSidebarList_OrganizationFragment$key } from "@/product/sidebar/__generated__/ProductsSidebarList_OrganizationFragment.graphql"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type Props = TestIDProps & {
  organizationKey?: ProductsSidebarList_OrganizationFragment$key | null
  navSectionKey?: ProductsSidebarList_NavSectionFragment$key | null
}

export default function ProductsSidebarList(props: Props) {
  const { organizationKey, navSectionKey, testid = "ProductsSidebarList" } = props
  const activeOrganization = useActiveOrganization()!
  const canReorder = activeOrganization.viewerPermissions.has("products.manage")
  const isAdminViewingAsMember = useIsAdminViewingAsMember()
  const activeProductSlug = useProductSlug()
  const classes = useStyles()
  const productLabel = useLabel("admin_experience")
  const theme = useTheme()
  const isExplorePageShown = useIsExplorePageShown()

  const organization = useFragment<ProductsSidebarList_OrganizationFragment$key>(
    graphql`
      fragment ProductsSidebarList_OrganizationFragment on Organization {
        id
        topLevelProducts: products(
          types: [course, pathway]
          noNavSection: true
          orderBy: nav
          first: null
        ) @connection(key: "ProductsSidebarList_OrganizationFragment__topLevelProducts") {
          edges {
            node {
              id
              slug
              status
              type
              viewerMembership {
                id
              }
              ...MyExperiencesListItemFragment
              ...PathwaySidebarItemFragment
            }
          }
        }
      }
    `,
    organizationKey || null
  )
  const navSection = useFragment<ProductsSidebarList_NavSectionFragment$key>(
    graphql`
      fragment ProductsSidebarList_NavSectionFragment on NavSection {
        id
        products(first: null)
          @connection(key: "ProductsSidebarList_NavSectionFragment__products") {
          edges {
            node {
              id
              slug
              status
              type
              ...MyExperiencesListItemFragment
              ...PathwaySidebarItemFragment
            }
          }
        }
      }
    `,
    navSectionKey || null
  )
  let products = organization
    ? Relay.connectionToArray(organization.topLevelProducts).filter(
        // Hide products viewer isn't a member of if not an org admin
        (p) => canReorder || p.viewerMembership
      )
    : Relay.connectionToArray(navSection?.products)

  // Hide draft products when the admin is viewing as member (unless inside it)
  if (isAdminViewingAsMember) {
    products = products.filter((p) => {
      if (activeProductSlug && p.slug === activeProductSlug) return true
      return p.status === "published"
    })
  }

  if (!products.length && !isExplorePageShown)
    return (
      <div className={organization ? classes.topLevel : undefined} data-testid={testid}>
        <DiscoText
          variant={"body-md"}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.400"
          }
          marginTop={1}
          marginBottom={1}
        >
          {`No ${productLabel.plural} yet.`}
        </DiscoText>
      </div>
    )

  return (
    <>
      {organization && products.length > 0 && isExplorePageShown && (
        <DiscoDivider marginTop={1.5} />
      )}
      <div className={organization ? classes.topLevel : undefined} data-testid={testid}>
        <ProductsSidebarProductsDragDrop
          navSectionId={navSection?.id}
          disabled={!canReorder}
          products={products}
          emptyState={getEmptyState()}
          showEmptyStateOnDrag={!navSection?.id}
        >
          {(product, dragHandleProps, isDragging) =>
            product.type === "course" ? (
              <MyExperiencesListItem
                testid={`${testid}.${product.slug}`}
                productKey={product}
                dragHandleProps={dragHandleProps}
                isDragging={isDragging}
              />
            ) : (
              <PathwaySidebarItem
                testid={`${testid}.${product.slug}`}
                productKey={product}
                dragHandleProps={dragHandleProps}
                isDragging={isDragging}
              />
            )
          }
        </ProductsSidebarProductsDragDrop>
      </div>
    </>
  )

  function getEmptyState() {
    if (!canReorder) return null
    if (navSection)
      return (
        <DiscoText
          variant={"body-sm"}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.300" : "groovy.neutral.300"
          }
          marginLeft={2}
          marginTop={1}
          marginBottom={1}
        >
          {`No ${productLabel.plural} here yet.`}
        </DiscoText>
      )
    // Show an empty section when dragging so products can be dragged into it
    return (
      <DiscoDivider
        marginTop={1.5}
        marginBottom={1.5}
        className={classes.emptyStateDivider}
      />
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  topLevel: {
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(1.5),
  },
  emptyStateDivider: {
    marginLeft: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)}px)`,
  },
}))
