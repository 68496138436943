import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductRegistrationAvailability } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import FormStore from "@/core/form/store/FormStore"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { generateReadablePricing } from "@/pricing/pricingUtils"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import useExperienceSettingsSelectedPlans from "@/product/settings/hooks/useExperienceSettingsSelectedPlans"
import { useExperienceSettingsSelectedPlans_membershipPlansFragment$key } from "@/product/settings/hooks/__generated__/useExperienceSettingsSelectedPlans_membershipPlansFragment.graphql"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import ProductUtils from "@/product/util/productUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoChip,
  DiscoDivider,
  DiscoFormControl,
  DiscoLink,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import React from "react"

interface Props extends TestIDProps {
  form: FormStore<
    CreateExperienceFormState | ExperienceSettingsFormState,
    ExperienceSettingsFormMutation
  >
  organizationKey: useExperienceSettingsSelectedPlans_membershipPlansFragment$key
  hideMembershipPlans?: boolean
}

function ExperienceSettingsAccessRadioGroup(props: Props) {
  const {
    form,
    testid = "ExperienceSettingsAccessRadioGroupFormFields",
    organizationKey,
    hideMembershipPlans,
  } = props
  const activeProduct = useActiveProduct()
  const activeOrganization = useActiveOrganization()!
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")
  const classes = useStyles()

  const { hasCustomPlans, selectedPlans } = useExperienceSettingsSelectedPlans({
    organizationKey,
    benefits: form.state.benefits,
  })

  return (
    <>
      <DiscoFormControl
        label={
          <ExperienceSettingsLabel
            title={"Access"}
            tooltipContent={`Access to this ${experienceLabel.singular} is also dependent on the membership plans it is available on.`}
            sectionId={"experience_access"}
          />
        }
        sublabel={`Choose who can view the registration page and join this ${experienceLabel.singular}.`}
        error={Boolean(form.errorsByField.registrationAvailability)}
        errorMessages={form.errorsByField.registrationAvailability}
      >
        <RadioGroup
          onChange={handleAvailabilityChange}
          value={form.state.registrationAvailability}
          row
          style={{ gap: "12px" }}
        >
          {/* Public */}
          <DiscoRadioBox
            testid={`${testid}.radio.public`}
            value={"public"}
            icon={ProductUtils.getAvailabilityIcon("public")}
            title={"Public"}
            subtitle={`${membersLabel.singular} and anyone on the internet can join this ${experienceLabel.singular} through the registration page.`}
          />

          {/* Private / Members Only */}
          <DiscoRadioBox
            testid={`${testid}.radio.private`}
            value={"private"}
            icon={ProductUtils.getAvailabilityIcon("private")}
            title={ProductUtils.getAvailabilityLabel("private", membersLabel)}
            subtitle={`Only ${membersLabel.plural} can join this ${experienceLabel.singular} through the registration page.`}
          />

          {/* Hidden / Invite Only */}
          <DiscoRadioBox
            testid={`${testid}.radio.hidden`}
            value={"hidden"}
            icon={ProductUtils.getAvailabilityIcon("hidden")}
            title={ProductUtils.getAvailabilityLabel("hidden", membersLabel)}
            subtitle={`Only those invited can join this ${experienceLabel.singular} through an email invitation.`}
          />
        </RadioGroup>
      </DiscoFormControl>

      {/* Membership Plans */}
      {!hideMembershipPlans && activeProduct && hasCustomPlans && (
        <DiscoSection className={classes.container}>
          <ExperienceSettingsLabel
            title={"Available Membership Plans"}
            sectionId={"available_memberships"}
          />
          <DiscoText
            variant={"body-sm"}
            color={"text.secondary"}
            display={"inline"}
            marginBottom={2}
          >
            {`This ${experienceLabel.singular} is available through the following membership plans. `}

            <DiscoLink
              to={{
                search: setSearchParams<GlobalDrawerParams<"experienceSettings">>(
                  location.search,
                  {
                    drawerExperienceId: activeProduct.id,
                    experienceSettingsTab: "pricing",
                  }
                ),
              }}
            >
              <DiscoText
                variant={"body-sm-700"}
                display={"inline"}
                color={"primary.main"}
              >
                {"Manage Memberships"}
              </DiscoText>
            </DiscoLink>
          </DiscoText>

          {selectedPlans.map((plan, index) => (
            <>
              {index > 0 && <DiscoDivider marginTop={1.5} marginBottom={1.5} />}

              {/* Plan */}
              <div className={classes.plan}>
                <div className={classes.coverAndDetails}>
                  <DiscoImage
                    className={classes.cover}
                    src={plan.cover || defaultThumbnail}
                  />

                  <div>
                    <DiscoText variant={"body-md-600"}>{plan.name}</DiscoText>

                    {plan.pricing && (
                      <DiscoText variant={"body-sm"} color={"text.secondary"}>
                        {generateReadablePricing(
                          plan.pricing,
                          activeOrganization.currency
                        )}
                      </DiscoText>
                    )}
                  </div>
                </div>

                <div>
                  <DiscoChip
                    label={pluralize(
                      "Product",
                      plan.membershipBenefits?.totalCount,
                      true
                    )}
                  />
                </div>
              </div>
            </>
          ))}
        </DiscoSection>
      )}
    </>
  )

  function handleAvailabilityChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.registrationAvailability = event.target
      .value as ProductRegistrationAvailability
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    margin: theme.spacing(1.5, 0, 2, 0),
    display: "flex",
    flexDirection: "column",
    boxShadow: theme.palette.groovyDepths.insideCard,
    padding: theme.spacing(2),
  },
  plan: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  coverAndDetails: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  cover: {
    height: "50px",
    display: "block",
    width: "auto",
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export default observer(ExperienceSettingsAccessRadioGroup)
