import { ManualActiveProductProvider } from "@/core/context/ActiveProductContext"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CreateInvitationButton from "@/invitation/create/button/CreateInvitationButton"
import ExperienceSettingsAvailabilityFormFields, {
  ExperienceSettingsAvailabilityFormFieldsSkeleton,
} from "@/product/settings/ExperienceSettingsAvailabilityFormFields"
import ExperienceSettingsDetailsFormFields, {
  ExperienceSettingsDetailsFormFieldsSkeleton,
} from "@/product/settings/ExperienceSettingsDetailsFormFields"
import ExperienceSettingsGroupsTab, {
  ExperienceSettingsGroupsTabSkeleton,
} from "@/product/settings/ExperienceSettingsGroupsTab"
import ExperienceSettingsNotificationsFormFields, {
  ExperienceSettingsNotificationsFormFieldsSkeleton,
} from "@/product/settings/ExperienceSettingsNotificationsFormFields"
import { ExperienceSettingsRegistrationFormFieldsSkeleton } from "@/product/settings/ExperienceSettingsRegistrationFormFields"
import ExperienceSettingsTeamTab, {
  ExperienceSettingsTeamTabSkeleton,
} from "@/product/settings/ExperienceSettingsTeamTab"
import ExperiencePricingFormFields, {
  ExperiencePricingFormFieldsSkeleton,
} from "@/product/settings/pricing/ExperiencePricingFormFields"
import { ExperienceSettingsFormFragment$key } from "@/product/settings/__generated__/ExperienceSettingsFormFragment.graphql"
import {
  EditExperienceInput,
  ExperienceSettingsFormMutation,
  PricingKind,
} from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { generateRandomString } from "@utils/string/stringUtils"
import { isBefore } from "date-fns"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { generatePath } from "react-router-dom"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"

export type ExperienceSettingsFormState = EditExperienceInput & {
  allowEarlyAccess: boolean
  showCapacity: boolean
  isPaid: boolean
  selectedMemberships: { id: GlobalID; memberId: GlobalID }[]
  price: number
  benefits: {
    id?: GlobalID | null
    membershipPlanId: GlobalID
  }[]
}

interface Props {
  experienceKey: ExperienceSettingsFormFragment$key
}

function ExperienceSettingsForm({ experienceKey }: Props) {
  const classes = useStyles()
  const drawer = useGlobalDrawer("experienceSettings")
  const { closeDrawer } = useDrawerContext()
  const unsavedChangesModal = useUnsavedChangesModalContext()
  const autoJoinWarningModal = useDisclosure()
  const membersLabel = useLabel("organization_member")

  const product = useFragment<ExperienceSettingsFormFragment$key>(
    graphql`
      fragment ExperienceSettingsFormFragment on Product {
        id
        ...ExperienceSettingsForm_SettingsFragment @relay(mask: false)
        ...ExperienceSettingsNotificationsFormFieldsFragment
        ...ExperienceSettingsGroupsTabFragment
        ...ExperienceSettingsTeamTabFragment
        ...ActiveProductContextFragment
        ...ExperienceSettingsStatusRadioGroupFragment
        ...ExperienceSettingsDurationFieldsFragment
      }
    `,
    experienceKey
  )

  const form = useFormStore<ExperienceSettingsFormMutation, ExperienceSettingsFormState>(
    graphql`
      mutation ExperienceSettingsFormMutation($input: EditExperienceInput!) {
        response: editExperience(input: $input) {
          node {
            id
            ...ExperienceSettingsForm_SettingsFragment @relay(mask: false)
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: product.id,
      slug: product.slug,
      badge: {
        kind: product.badge?.kind || "icon",
        icon: product.badge?.icon,
        color: product.badge?.color,
        emoji: product.badge?.emoji,
        mediaUrl: product.badge?.mediaUrl,
      },
      typeTag: {
        label: product.typeTag?.label,
        color: product.typeTag?.color,
      },
      name: product.name,
      description: product.description,
      cover: product.cover,
      experienceUrl: product.slug,
      landingPageUrl: product.landingPageUrl,
      landingPage: {
        mode: product.landingPage?.mode,
        metaTitle: product.landingPage?.metaTitle,
        metaDescription: product.landingPage?.metaDescription,
        metaImageUrl: product.landingPage?.metaImageUrl,
      },
      status: product.status,
      startDate: product.startDate,
      endDate: product.endDate,
      waitingRoomEndsAt: product.waitingRoomEndsAt || product.startDate,
      sendEarlyAccessStartedEmail: product.sendEarlyAccessStartedEmail,
      registrationAvailability: product.registrationAvailability,
      registrationType: product.registrationType,
      waitlistUrl: product.waitlistUrl,
      waitlistCtaLabel: product.waitlistCtaLabel,
      isAutoJoined: product.isAutoJoined,
      capacity: product.capacity,
      sendNewCommentEmail: product.sendNewCommentEmail,
      sendCourseReminderEmail: product.sendCourseReminderEmail,
      sendCourseRegistrationEmailToAttendees:
        product.sendCourseRegistrationEmailToAttendees,
      sendAssignmentDueNotifications: product.sendAssignmentDueNotifications,
      replyToEmailAddress: product.replyToEmailAddress,
      richEditorCheckoutDescription: product.richEditorCheckoutDescription,

      // Additional state variables
      allowEarlyAccess: isBefore(
        new Date(product.waitingRoomEndsAt!),
        new Date(product.startDate!)
      ),
      showCapacity: product?.capacity !== null,
      applicationQuestions: product?.applicationQuestions?.edges.length
        ? Relay.connectionToArray(product?.applicationQuestions)
        : [
            {
              id: Relay.toGlobalId("Question", generateRandomString()),
              richEditorBody: null,
              type: "free-text",
              isRequired: false,
            },
          ],
      selectedMemberships: observable([]),
      price: product.registrationPricing?.basePrice || 0,
      isPaid: Boolean(product?.registrationPricing?.amountCents),
      benefits: generateBenefits(),
      adminCanLearnMode: product.adminCanLearnMode,
    }
  )

  useEffect(() => {
    unsavedChangesModal.setUnsavedChanges(form.isChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.isChanged, unsavedChangesModal.setUnsavedChanges])
  const experienceLabel = useLabel("experience")

  if (!product) return null

  return (
    <>
      <ManualActiveProductProvider productKey={product}>
        <Form
          id={"ExperienceSettingsForm"}
          testid={"ExperienceSettingsForm"}
          classes={{ formFieldsContainer: classes.form }}
          height={"100%"}
          hasStickyButtons
          buttons={renderButtons()}
        >
          {renderSettingsFormFields()}
        </Form>
      </ManualActiveProductProvider>
      <DiscoWarningModal
        testid={`ExperienceSettingsForm.AutoJoinWarningModal`}
        isOpen={autoJoinWarningModal.isOpen}
        onClose={autoJoinWarningModal.onClose}
        title={"Enable Auto-Add?"}
        modalContentLabel={"Auto-Add Warning"}
        description={`Are you sure you want to enable Auto-Add? Auto-Add will automatically register ${membersLabel.plural} for this ${experienceLabel.singular} immediately. This action can't be undone.`}
        confirmationButtonProps={{
          onClick: handleSubmit,
          children: "Yes, enable it",
          shouldDisplaySpinner: form.isSubmitting,
          disabled: form.isSubmitting,
        }}
      />
    </>
  )

  function renderSettingsFormFields() {
    if (!product) return

    switch (drawer.params.experienceSettingsTab) {
      case "details":
        return <ExperienceSettingsDetailsFormFields form={form} productKey={product} />
      case "availability":
        return (
          <ExperienceSettingsAvailabilityFormFields
            form={form}
            organizationKey={product.organization}
            productKey={product}
          />
        )
      case "pricing":
        return (
          <ExperiencePricingFormFields
            form={form}
            organizationKey={product.organization}
          />
        )
      case "notifications":
        return (
          <ExperienceSettingsNotificationsFormFields
            experienceKey={product}
            form={form}
          />
        )
      case "groups":
        return <ExperienceSettingsGroupsTab experienceKey={product} />
      case "team":
        return <ExperienceSettingsTeamTab experienceKey={product} />
      default:
        return <ExperienceSettingsDetailsFormFields form={form} productKey={product} />
    }
  }

  function renderButtons() {
    if (drawer.params.experienceSettingsTab === "groups") return null
    if (drawer.params.experienceSettingsTab === "team")
      return (
        <CreateInvitationButton productId={form.state.id} onlyAdminRoles>
          {({ onClick }) => (
            <DiscoButton
              onClick={onClick}
              leftIcon={"add"}
              testid={"ExperienceSettingsTeamTab.add-teacher-button"}
            >
              {"Add to Team"}
            </DiscoButton>
          )}
        </CreateInvitationButton>
      )
    return (
      <>
        <DiscoButton
          testid={"ExperienceSettingsForm.cancel-button"}
          color={"grey"}
          variant={"outlined"}
          onClick={handleCloseDrawer}
        >
          {"Cancel"}
        </DiscoButton>

        <DiscoButton
          testid={"ExperienceSettingsForm.submit-button"}
          onClick={
            form.changedState.isAutoJoined ? autoJoinWarningModal.onOpen : handleSubmit
          }
          disabled={!form.isChanged}
          shouldDisplaySpinner={form.isSubmitting}
        >
          {"Save"}
        </DiscoButton>
      </>
    )
  }

  function handleCloseDrawer() {
    unsavedChangesModal.handleLeave({
      onLeave: () => closeDrawer?.(),
    })
  }

  /** Generate the membership benefits that is experience is included in */
  function generateBenefits(): {
    id: GlobalID | null
    membershipPlanId: GlobalID
  }[] {
    return Relay.connectionToArray(product.includedInBenefits).map((b) => ({
      id: b.id,
      membershipPlanId: b.membershipPlan.id,
    }))
  }

  async function handleSubmit() {
    try {
      // Remove temporary states
      const {
        showCapacity,
        allowEarlyAccess: changedAllowEarlyAccess,
        isPaid: _isPaid,
        selectedMemberships: _selectedMemberships,
        price: _price = 0,
        benefits: _benefits,
        waitingRoomEndsAt: changedWaitingRoomEndsAt, // for safety, remove from changed state so we can evaluate correct value below
        ...changedState
      } = form.changedState

      const { isPaid, price, benefits } = form.state

      // Create the update benefits object for this experience, if membershipPlans
      //  is disabled, format the price as paid/free to match the existing behaviour
      const updateBenefits = benefits.map((b) => ({
        id: b.id,
        membershipPlanId: b.membershipPlanId,
        productId: form.state.id,
        pricing: {
          amountCents: isPaid ? price * 100 : 0,
          kind: (isPaid ? "one_time" : "free") as PricingKind,
          frequency: null,
        },
      }))

      const shouldInvalidateMemberships =
        !form.initialState.isAutoJoined &&
        changedState.isAutoJoined &&
        location.pathname.startsWith(
          generatePath(ROUTE_NAMES.PRODUCT.MEMBERS.ROOT, { productSlug: product.slug })
        )

      let waitingRoomEndsAt

      // ensure in all cases if fixed duration is toggled off (start and end date null), the waiting room is null too
      if (form.state.startDate === null && form.state.endDate === null) {
        if (form.state.waitingRoomEndsAt !== null) waitingRoomEndsAt = null
      }
      // otherwise if start date, waiting room, or early access changes, set waiting room to valid value
      else if (
        changedAllowEarlyAccess !== undefined ||
        changedWaitingRoomEndsAt ||
        changedState.startDate
      ) {
        if (form.state.allowEarlyAccess && form.state.waitingRoomEndsAt)
          waitingRoomEndsAt = new Date(form.state.waitingRoomEndsAt).toISOString()
        else waitingRoomEndsAt = new Date(form.state.startDate!).toISOString()
      }

      const { didSave } = await form.submit(
        {
          id: form.state.id,
          ...changedState,
          waitingRoomEndsAt,
          ...(!showCapacity &&
            showCapacity !== undefined && {
              capacity: null,
            }),
          ...(Object.keys(changedState).includes("badge") && {
            badge: form.state.badge,
          }),
          ...(form.state.applicationQuestions && {
            applicationQuestions: form.state.applicationQuestions,
          }),
          ...(Object.keys(changedState).includes("typeTag") && {
            typeTag: form.state.typeTag,
          }),
          membershipBenefits: updateBenefits,
          ...(changedState.registrationType === "waitlist" && {
            waitlistUrl: form.state.waitlistUrl,
          }),
        },
        {
          updater: (store) => {
            // If status or dates changed, invalidate product admin actions list so relevant
            // ones can be refetched
            if (
              changedState.status === undefined &&
              changedState.startDate === undefined &&
              changedState.endDate === undefined
            )
              return
            const productRecord = store.get(form.state.id)
            if (!productRecord) return
            const actionsConnection = ConnectionHandler.getConnection(
              productRecord,
              "ProductAdminDashboardActions__productAdminActions"
            )
            actionsConnection?.invalidateRecord()
          },
        }
      )

      if (!didSave) return

      if (autoJoinWarningModal.isOpen) autoJoinWarningModal.onClose()

      if (shouldInvalidateMemberships) {
        // we need to refetch the members list connections if we're looking at memberships when we make the experience auto-joinable
        // in all other cases, the list should fetch from network on render
        commitLocalUpdate(RelayEnvironment, (store) => {
          const productRecord = store.get(product.id)
          if (productRecord) {
            const connections = ConnectionHandler.getConnections(
              productRecord,
              "ProductMembersList__productMemberships"
            )
            connections.forEach((connection) => {
              connection.invalidateRecord()
            })
          }
        })
      }

      displaySuccessToast({
        message: `${experienceLabel.singular} updated!`,
        testid: "ExperienceSettingsForm.success-toast",
      })
    } catch (error) {
      displayErrorToast(error)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    padding: theme.spacing(3, 3, 0),
  },
}))

const ExperienceSettingsFormSkeleton = () => {
  const drawer = useGlobalDrawer("experienceSettings")

  switch (drawer.params.experienceSettingsTab) {
    case "details":
      return <ExperienceSettingsDetailsFormFieldsSkeleton />
    case "availability":
      return <ExperienceSettingsAvailabilityFormFieldsSkeleton />
    case "registration":
      return <ExperienceSettingsRegistrationFormFieldsSkeleton />
    case "pricing":
      return <ExperiencePricingFormFieldsSkeleton />
    case "notifications":
      return <ExperienceSettingsNotificationsFormFieldsSkeleton />
    case "groups":
      return <ExperienceSettingsGroupsTabSkeleton />
    case "team":
      return <ExperienceSettingsTeamTabSkeleton />
    default:
      return <ExperienceSettingsDetailsFormFieldsSkeleton />
  }
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperienceSettingsForm),
  skeleton: ExperienceSettingsFormSkeleton,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ExperienceSettingsForm_SettingsFragment on Product {
    id
    organizationId
    slug
    badge {
      kind
      icon
      color
      emoji
      mediaUrl
    }
    name
    description
    cover
    typeTag {
      label
      color
    }
    landingPageUrl
    landingPage {
      mode
      metaTitle
      metaDescription
      metaImageUrl
    }
    status
    startDate
    endDate
    startsAt
    endsAt
    waitingRoomEndsAt
    sendEarlyAccessStartedEmail
    registrationAvailability
    registrationType
    waitlistUrl
    waitlistCtaLabel
    applicationQuestions {
      edges {
        node {
          id
          richEditorBody
          type
          isRequired
        }
      }
    }
    isAutoJoined
    capacity
    hasPrice
    registrationPricing {
      amountCents
      basePrice
      kind
      frequency
    }
    includedInBenefits {
      edges {
        node {
          id
          pricing {
            id
            amountCents
            frequency
            kind
          }
          membershipPlan {
            id
          }
        }
      }
    }
    organization {
      ...useExperienceSettingsSelectedPlans_membershipPlansFragment
    }
    sendNewCommentEmail
    sendCourseRegistrationEmailToAttendees
    sendCourseReminderEmail
    sendAssignmentDueNotifications
    replyToEmailAddress
    richEditorCheckoutDescription
    adminCanLearnMode
    ...ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment
  }
`
