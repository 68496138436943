import { useLabel } from "@/core/context/LabelsContext"
import { ProductRegistrationAvailability } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import useExperienceSettingsSelectedPlans from "@/product/settings/hooks/useExperienceSettingsSelectedPlans"
import { useExperienceSettingsSelectedPlans_membershipPlansFragment$key } from "@/product/settings/hooks/__generated__/useExperienceSettingsSelectedPlans_membershipPlansFragment.graphql"
import {
  DiscoAlert,
  DiscoFormControl,
  DiscoSection,
  DiscoSectionProps,
  DiscoSwitch,
  DiscoText,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
interface ExperienceSettingsAutojoinFormFieldProps extends TestIDProps {
  form: {
    state: {
      registrationAvailability?: ProductRegistrationAvailability | null
      isAutoJoined?: boolean | null
      benefits: ExperienceSettingsFormState["benefits"]
    }
  }
  groovyDepths?: DiscoSectionProps["groovyDepths"]
  organizationKey: useExperienceSettingsSelectedPlans_membershipPlansFragment$key
}

function ExperienceSettingsAutojoinFormField({
  form,
  groovyDepths,
  testid = "ExperienceSettingsAutojoinFormField",
  organizationKey,
}: ExperienceSettingsAutojoinFormFieldProps) {
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")

  const { hasCustomPlans, defaultFreePlan } = useExperienceSettingsSelectedPlans({
    organizationKey,
    benefits: form.state.benefits,
  })

  useEffect(() => {
    if (form.state.registrationAvailability === "hidden") {
      // ensure we are not selecting auto-join if experience is invite only
      form.state.isAutoJoined = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.registrationAvailability])

  // Shouldn't be able to make a Private experience (invitation only) auto-join
  if (form.state.registrationAvailability === "hidden" && !form.state.isAutoJoined)
    return null

  return (
    <DiscoSection
      groovyDepths={groovyDepths}
      paddingRight={groovyDepths === undefined ? 0 : 3}
      paddingLeft={groovyDepths === undefined ? 0 : 3}
      paddingTop={groovyDepths === undefined ? 0 : 3}
      paddingBottom={groovyDepths === undefined ? 0 : 1}
      testid={`${testid}.section`}
    >
      <DiscoFormControl>
        <DiscoSwitch
          testid={`${testid}.switch`}
          label={
            <ExperienceSettingsLabel
              title={`Auto-Add All ${membersLabel.plural}`}
              sectionId={"experience_autojoin"}
              tooltipContent={tooltipContent()}
            />
          }
          checked={form.state.isAutoJoined!}
          onChange={(checked) => (form.state.isAutoJoined = checked)}
          sublabel={<DiscoText variant={"body-sm"}>{sublabel()}</DiscoText>}
        />
      </DiscoFormControl>
      {form.state.isAutoJoined && (
        <DiscoAlert
          message={alertMessage()}
          marginBottom={groovyDepths === undefined ? 0 : 2}
        />
      )}
    </DiscoSection>
  )

  function alertMessage() {
    return `${membersLabel.plural} of ${
      hasCustomPlans
        ? "any membership plans listed above that provide"
        : `the default free plan, '${defaultFreePlan?.name}', that provides`
    } access to this ${experienceLabel.singular} will bypass all ${
      experienceLabel.singular
    } access restrictions including one-time fees, applications, and capacity, and will automatically be registered in the ${
      experienceLabel.singular
    } immediately.`
  }

  function sublabel() {
    return `Automatically add ${membersLabel.plural} to this ${
      experienceLabel.singular
    } that have access through ${
      hasCustomPlans ? "a membership plan listed above" : `the default free plan`
    }.`
  }

  function tooltipContent() {
    return `Use auto-add to automatically register all new and existing ${
      membersLabel.plural
    } so the ${experienceLabel.singular} is instantly accessible to them. Only ${
      membersLabel.plural
    } on ${
      hasCustomPlans
        ? `a membership plan that this ${experienceLabel.singular} is available on`
        : `the default free plan, '${defaultFreePlan?.name}',`
    } will be auto added.`
  }
}

export default observer(ExperienceSettingsAutojoinFormField)
