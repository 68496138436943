/**
 * @generated SignedSource<<7d7ea3cb81e68e8be5e127445a7cf0d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberCurriculumProgressBarFragment$data = {
  readonly id: string;
  readonly curriculumModuleCompletions: {
    readonly totalCount: number;
  };
  readonly product: {
    readonly curriculum: {
      readonly modules: {
        readonly totalCount: number;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly releasedAt: string | null;
          };
        }>;
      };
    } | null;
  };
  readonly " $fragmentType": "MemberCurriculumProgressBarFragment";
};
export type MemberCurriculumProgressBarFragment$key = {
  readonly " $data"?: MemberCurriculumProgressBarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberCurriculumProgressBarFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberCurriculumProgressBarFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentCompletionNodeConnection",
      "kind": "LinkedField",
      "name": "curriculumModuleCompletions",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Curriculum",
          "kind": "LinkedField",
          "name": "curriculum",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeConnection",
              "kind": "LinkedField",
              "name": "modules",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsageNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentUsage",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "releasedAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};
})();

(node as any).hash = "50b6409b0e633e00894870ac4cc5196c";

export default node;
