/**
 * @generated SignedSource<<b24f283ee6ad7f4aa17eb7b64bdec75a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberCurriculumProgressBarWithQueryQuery$variables = {
  id: string;
};
export type MemberCurriculumProgressBarWithQueryQuery$data = {
  readonly member: {
    readonly " $fragmentSpreads": FragmentRefs<"MemberCurriculumProgressBarFragment">;
  } | null;
};
export type MemberCurriculumProgressBarWithQueryQuery = {
  variables: MemberCurriculumProgressBarWithQueryQuery$variables;
  response: MemberCurriculumProgressBarWithQueryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MemberCurriculumProgressBarWithQueryQuery",
    "selections": [
      {
        "alias": "member",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MemberCurriculumProgressBarFragment"
              }
            ],
            "type": "ProductMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MemberCurriculumProgressBarWithQueryQuery",
    "selections": [
      {
        "alias": "member",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentCompletionNodeConnection",
                "kind": "LinkedField",
                "name": "curriculumModuleCompletions",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Curriculum",
                    "kind": "LinkedField",
                    "name": "curriculum",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsageNodeConnection",
                        "kind": "LinkedField",
                        "name": "modules",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentUsage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "releasedAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ProductMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "611efed6d9947bfdd9900e4eec7b50c2",
    "id": null,
    "metadata": {},
    "name": "MemberCurriculumProgressBarWithQueryQuery",
    "operationKind": "query",
    "text": "query MemberCurriculumProgressBarWithQueryQuery(\n  $id: ID!\n) {\n  member: node(id: $id) {\n    __typename\n    ... on ProductMembership {\n      ...MemberCurriculumProgressBarFragment\n    }\n    id\n  }\n}\n\nfragment MemberCurriculumProgressBarFragment on ProductMembership {\n  id\n  curriculumModuleCompletions {\n    totalCount\n  }\n  product {\n    curriculum {\n      modules {\n        totalCount\n        edges {\n          node {\n            id\n            releasedAt\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e81996f8274261e699d6aeef692daa53";

export default node;
