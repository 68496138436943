/**
 * @generated SignedSource<<2178c6dead039ee32f8542c77190f1f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperienceSettingsDurationFieldsFragment$data = {
  readonly isInPathway: boolean;
  readonly " $fragmentType": "ExperienceSettingsDurationFieldsFragment";
};
export type ExperienceSettingsDurationFieldsFragment$key = {
  readonly " $data"?: ExperienceSettingsDurationFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceSettingsDurationFieldsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceSettingsDurationFieldsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInPathway",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "644a1af9feb960f1e1fd1d0377e638ee";

export default node;
