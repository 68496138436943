/**
 * @generated SignedSource<<ab7dd65e7560b1fa26115c7eb4030bca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment$data = {
  readonly parentPathwayProducts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly hasDraftChildProducts: boolean;
      };
    }>;
  };
  readonly " $fragmentType": "ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment";
};
export type ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment$key = {
  readonly " $data"?: ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "parentPathwayProducts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasDraftChildProducts",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "c2e4fa4ec6a7ccf0e3f9f3ac3254b013";

export default node;
