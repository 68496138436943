import PublishPathwayButton from "@/admin/pathways/PublishPathwayButton"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import PublishExperienceButton from "@/product/buttons/PublishProduct/PublishExperienceButton"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import { ProductVisibilityBannerQuery } from "@/product/util/__generated__/ProductVisibilityBannerQuery.graphql"
import Relay from "@/relay/relayUtils"
import { Spacing } from "@assets/style/appMuiTheme"
import { DiscoAlert, DiscoLink, DiscoText } from "@disco-ui"
import { graphql } from "relay-runtime"

function ProductVisibilityBanner(spacing: Spacing) {
  const activeProduct = useActiveProduct()!
  const isUnpublished = activeProduct.status === "draft"
  const isPathway = activeProduct.type === "pathway"
  const hasPublishPermission = activeProduct.viewerPermissions.has(
    isPathway ? "pathways.manage" : "product_status.manage"
  )
  const memberLabel = useLabel("organization_member")
  const experienceLabel = useLabel("admin_experience")
  const isViewingAsMember = useIsAdminViewingAsMember()

  const { node: pathwayProduct } =
    Relay.useSkippableLazyLoadQuery<ProductVisibilityBannerQuery>(
      graphql`
        query ProductVisibilityBannerQuery($productId: ID!) {
          node(id: $productId) {
            ... on Product {
              hasDraftChildProducts
            }
          }
        }
      `,
      { productId: activeProduct.id ?? "" },
      { skip: !activeProduct.id || !isPathway }
    )
  const hasUnpublishedChildProducts = !!pathwayProduct?.hasDraftChildProducts

  const canPublish = isPathway
    ? hasPublishPermission && !hasUnpublishedChildProducts
    : hasPublishPermission

  // Hide the banner if the product is published or in view as member mode
  if (!isUnpublished || isViewingAsMember) return null

  return (
    <DiscoAlert
      {...spacing}
      severity={"warning"}
      data-testid={"ProductVisibilityBanner.alert"}
      message={
        <>
          <DiscoText variant={"body-sm"}>
            {`This ${activeProduct.typeLabel} is currently in draft mode and not visible to ${memberLabel.plural}. `}
            {isPathway &&
              hasUnpublishedChildProducts &&
              `Publish the draft ${experienceLabel.plural} in this ${activeProduct.typeLabel} to start taking registrations.`}
            {canPublish && (
              <>
                {getPublishButton()}
                {` this ${activeProduct.typeLabel} to start taking registrations.`}
              </>
            )}
          </DiscoText>
        </>
      }
    />
  )

  function getPublishButton() {
    if (isPathway)
      return (
        <PublishPathwayButton>
          {(buttonProps) => (
            <DiscoLink
              {...buttonProps}
              textVariant={"body-sm"}
              data-testid={"ProductVisibilityBanner.alert.publish"}
            >
              {"Publish"}
            </DiscoLink>
          )}
        </PublishPathwayButton>
      )
    return (
      <PublishExperienceButton>
        {(buttonProps) => (
          <DiscoLink
            {...buttonProps}
            textVariant={"body-sm"}
            data-testid={"ProductVisibilityBanner.alert.publish"}
          >
            {"Publish"}
          </DiscoLink>
        )}
      </PublishExperienceButton>
    )
  }
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment on Product {
    parentPathwayProducts {
      edges {
        node {
          id
          hasDraftChildProducts
        }
      }
    }
  }
`

ProductVisibilityBanner.HEIGHT = "56px"

export default ProductVisibilityBanner
