/**
 * @generated SignedSource<<6e27a0c8510d1e91068e86a536027dc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LeaveProductButtonFragment$data = {
  readonly name: string;
  readonly slug: string;
  readonly type: ProductType;
  readonly viewerMembership: {
    readonly id: string;
    readonly organizationMembershipId: string;
  } | null;
  readonly " $fragmentType": "LeaveProductButtonFragment";
};
export type LeaveProductButtonFragment$key = {
  readonly " $data"?: LeaveProductButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeaveProductButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeaveProductButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationMembershipId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "5c1680fd5a9ea1f0d68a0ebc840096a5";

export default node;
