import { useLabels } from "@/core/context/LabelsContext"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl, DiscoSwitch, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
interface ExperienceSettingsLearnerModeFieldsProps extends TestIDProps {
  form: {
    initialState: Partial<Pick<ExperienceSettingsFormState, "adminCanLearnMode">>
    state: Partial<Pick<ExperienceSettingsFormState, "adminCanLearnMode">>
    errorsByField: Record<string, string[] | undefined>
  }
}

function ExperienceSettingsLearnerModeFields({
  form,
  testid = "ExperienceSettingsLearnerModeFields",
}: ExperienceSettingsLearnerModeFieldsProps) {
  const labels = useLabels()

  const classes = useStyles()

  return (
    <div>
      <DiscoFormControl
        error={Boolean(form.errorsByField.adminCanLearnMode)}
        errorMessages={form.errorsByField.adminCanLearnMode || []}
      >
        <div className={classes.switchContainer}>
          <DiscoSwitch
            name={"toggle-admin-can-learn-mode"}
            checked={Boolean(form.state.adminCanLearnMode)}
            testid={`${testid}.switch.fixed`}
            label={
              <ExperienceSettingsLabel
                testid={`${testid}.label`}
                title={`Allow Learning Mode for this ${labels.admin_experience.singular}`}
                tooltipContent={`They will be able to complete the curriculum, and their progress will be recorded in Admin Insights > Product Reports/Member Reports.`}
              />
            }
            sublabel={
              <DiscoText variant={"body-sm"}>
                {`${labels.organization_admin.plural} and ${labels.product_admin.plural} can switch to Learning Mode to complete the ${labels.admin_experience.singular} as a ${labels.product_member.singular}.`}
              </DiscoText>
            }
            onChange={(checked) => (form.state.adminCanLearnMode = checked)}
          />
        </div>
      </DiscoFormControl>
    </div>
  )
}

const useStyles = makeUseStyles({
  switchContainer: {
    display: "inline-flex",
    flexDirection: "column",
  },
})

export default observer(ExperienceSettingsLearnerModeFields)
